@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-copper: #FE6535;
  --color-copper-50: #FF9B53;
  --color-lemon-chiffon: #FFF7CE;
  --color-quartz: #4A4A4A;
  --color-philippine-silver: #B2B2B2;
  --swiper-theme-color: #FE6535 !important;
  --color-davys-grey: #5C5C5C;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-quartz);
  overscroll-behavior: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

@layer components {
  .highlight-place-card {
    @apply overflow-hidden;
    border-radius: 1rem;
    backdrop-filter: blur(2px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .qr-place-card {
    @apply w-[20rem] overflow-hidden;
    border-radius: 2.5rem 0.5rem 2.5rem 2.5rem;
  }
  .bg-copper {
    background: var(--color-copper);
  }
  .bg-copper-50 {
    background: var(--color-copper);
    opacity: 0.5;
  }
  .bg-lemon-chiffon {
    background: var(--color-lemon-chiffon);
  }
  .text-copper {
    color: var(--color-copper);
  }
  .text-copper-50 {
    color: var(--color-copper-50);
  }
  .text-quartz {
    color: var(--color-quartz);
  }
  .text-davys-grey{
    color: var(--color-davys-grey) !important;
  }
  .border-copper {
    border-color: var(--color-copper);
  }
  .border-philippine-silver {
    border-color: var(--color-philippine-silver);
  }
  .text-philippine-silver {
    color: var(--color-philippine-silver);
  }
  .text-2xs {
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
  .bg-home {
    background-image:url("http://placekitten.com/700/900");
  }
  .h-128 {
    height: 32rem;
  }
  .text-copper {
    color: var(--color-copper);
  }
  .ring-copper {
    --tw-ring-opacity: 1;
    --tw-ring-color: --color-copper;
  }

  .bannerImage {
     /* Add the blur effect */
    background-image:url("../public/banner.jpg");
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  }

  .policies {
    @apply p-6 space-y-4 h-[calc(100vh-10rem)] overflow-scroll;
  }

  .terms-and-conditions {
    @apply space-y-4;
  }

  .terms-and-conditions h1 {
    @apply text-2xl font-bold;
  }

  .terms-and-conditions h2 {
    @apply text-xl font-bold;
  }

  .terms-and-conditions p {
    @apply text-justify;
  }

  .data-policy {
    @apply space-y-4;
  }

  .data-policy h1 {
    @apply text-2xl font-bold;
  }

  .data-policy h2 {
    @apply text-xl font-bold;
  }

  .data-policy p {
    @apply text-justify;
  }

  .habeas-data {
    @apply space-y-4;
  }

  .habeas-data h1 {
    @apply text-2xl font-bold;
  }

  .habeas-data h2 {
    @apply text-xl font-bold;
  }

  .habeas-data p {
    @apply text-justify;
  }

  .glow-teal {
    text-shadow: rgba(125, 252, 246, 0.8) 0 0 0.7em;
  }

  .glow-red {
    text-shadow: rgba(252, 165, 165, 0.8) 0 0 0.7em;
  }

  .glow-amber {
    text-shadow: rgba(252, 218, 125, 0.8) 0 0 0.7em;
  }

  .glow-copper {
    text-shadow: rgba(252, 120, 81, 0.8) 0 0 0.7em;
  }

  .white-shadow {
    box-shadow: 0 0 32px 5px rgba(255, 255, 255, 0.5);
  }
}

/* Swiper */
.swiper-button-prev {
  @apply flex justify-center items-center absolute left-1 top-1/2 cursor-pointer bg-white bg-opacity-80 text-copper z-20 text-3xl font-bold rounded-r-xl w-7 h-12;
  height: 3rem !important;
  width: 2rem !important;
  left: 0 !important;
  top: calc(50% - 1rem) !important;
}

.swiper-button-next {
  @apply flex justify-center items-center absolute right-1 top-1/2 cursor-pointer bg-white bg-opacity-80 text-copper z-20 text-3xl font-bold rounded-l-xl w-7 h-12;
  height: 3rem !important;
  right: 0 !important;
  width: 1.5rem !important;
  top: calc(50% - 1rem) !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 1.25rem !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: var(--color-davys-grey);
}

.highlight-carousel {
  padding-bottom: 1rem !important;
}

.highlight-carousel .swiper-slide {
  width: fit-content;
}