/* react-toastify */

.Toastify__progress-bar--success {
/* linear degrade */
background: linear-gradient(90deg, #DA3400 0%, #9747FF 100%);
}

/* payzen card form */
.kr-embedded .kr-payment-button {
    background-color: var(--color-copper) !important;
    text-align: center;
    border-radius: .2rem;
    font-family: 'Roboto', sans-serif;
}

.kr-popin-order-id {
    display: none !important;
}

.kr-popin-modal-header-image {
    display: none !important;
}

.kr-popin-modal-header-background {
    display: none !important;
}

.kr-smart-form .kr-smart-form-wrapper.kr-type-embedded .kr-smart-form-modal-wrapper .kr-smart-form-modal .kr-popin-modal-header.kr-smart-form-modal-header {
    background-color: #fff !important;
    height: fit-content !important;
    border: 0 !important;
}

.kr-smart-form .kr-smart-form-wrapper.kr-type-embedded .kr-smart-form-modal-wrapper .kr-smart-form-modal {
    padding-top: 2rem !important;
}

.kr-popin-modal-header-background-image {
    display: none !important;
}

.kr-popin-amount {
    display: none !important;
}

.kr-popin-shop-name {
    display: none !important;
}

.fi-x-circle {
    transform: scale(1.5) !important;
    margin: 9px !important;
}

.kr-embedded .kr-field-element {
    border: 1px solid var(--color-philippine-silver) !important;
    border-radius: 0.375rem !important;
    /* overflow: hidden !important; */
    height: 3rem !important;
    padding: 0.5rem !important;
}

.kr-smart-button {
    /* background-color: var(--color-copper) !important; */
    /* border: 0 !important; */
    border-radius: 0.375rem !important;
    padding: 0.5rem 1rem !important;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: #fff !important;
    cursor: pointer !important;
    transition: background-color 0.3s !important;
}

.kr-smart-button .kr-hover .kr-pse {
    border-color: var(--color-copper) !important;
}

.kr-smart-form .kr-smart-button:hover:not(.kr-loading, .kr-locked) {
    border: 1px solid var(--color-copper) !important;
    background-color: #fff;
}

.kr-smart-form .kr-smart-button .kr-method-info .kr-label label.kr-method-label {
    font-size: 1rem !important;
}

/* Swiper */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
    bottom: 0 !important;
    left: 0 !important;
    padding: 0 1rem;
}

.swiper-css-mode>.swiper-wrapper {
    overflow-y: visible !important;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow-y: visible !important;
    list-style: none;
    padding: 0;
    z-index: 1;
}

/* react-calendar */
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    /* border: 1px solid #a0a096; */
    font-family: 'Roboto', sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
}

.react-calendar__tile:disabled {
    background-color: #fff;
    color: #c9c9d0;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    background: #fff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: var(--color-copper);
    border-radius: 5rem;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--color-copper);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
